import { TripCardProps } from "../CurrentTrips";

export const CurrentTripsContent: TripCardProps[] = [
  {
    imgPath: "./images/trip20.jpg",
    title: "Zakończenie lata w Auuuuustrii",
    content: (
      <span>
        Sześć dni mocnej jazdy w trzech najlepszych Austriackich Bike Parkach !
        Saalbach-Hinterglemm, Leogang, Schladming
      </span>
    ),
    tripId: 0,
    date: "21-28 Września 2024",
  },
  // {
  //   imgPath: "./images/cards/card4.png",
  //   title: "BIKE PARK SŁOTWINY ARENA",
  //   content: <span>Wyjazd szkoleniowy dla dzieci i młodzieży. <br/>6 godzin szkolenia - Poziom Średniozaawansowany.</span>,
  //   tripId: 0,
  //   date: 'Sobota 17.06.2023',
  // },
  // {
  //   imgPath: "test_trip_img.jpg",
  //   title: "Zakopane",
  //   content: <span>Wyjazd szkoleniowy dla dzieci i młodzieży. 5H Szkolenia- Poziom Średniozaawansowany</span>,
  //   date: '13.05.2023',
  //   tripId: 1,
  // },
];
